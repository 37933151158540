import React from 'react'
import style from './Footer.module.css'
import { Link } from 'react-router-dom'

export default function Footer() {
  return <>
  <span className='text-white small text-center py-5 mt-sm-5'>Copyright © ElMekyal AlSaudi 2024 Powered by
    <Link className='fw-semibold text-white text-decoration-none' to={'https://mas-itc.com'} target='_blank'> MAS-ITC</Link>
  </span>
  </>
}
