import React, { useContext } from 'react'
import './assets/App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Layout from './Component/Layout/Layout.jsx';
import ComingSoon from './Component/ComingSoon/ComingSoon.jsx';
import { Helmet } from 'react-helmet';
import { LangContext } from './Component/Context/LangContext.js';


let routers = createBrowserRouter([
  { path: '', element: <Layout />, children: [
    { index: true, element: <ComingSoon /> },
    { path: '*', element: <ComingSoon /> },
  ]}
])

export default function App() {

  let {language}= useContext(LangContext);

  return <>
  <Helmet>
      <title>{language === 'en' ? 'AlMekyal AlSaudi' : 'المكيال السعودي'}</title>
  </Helmet>
  <RouterProvider router={routers}></RouterProvider>
  
  </>
}