import React, { useContext } from 'react'
import style from './ComingSoon.module.css'
import labLogo from '../../assets/images/logo-mekyal-saudi.png'
import infoLogo from '../../assets/images/Picture1-150x150.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../Context/LangContext'

export default function ComingSoon() {
  const { t } = useTranslation();
  let {language}= useContext(LangContext);

  return <>
<div className={`text-white ${language === 'en' ? 'roboto-font' : 'cairo-font'}`}>

                                                      {/* header */}
  <div className='text-center fw-bold pb-5 mb-5'>
      <h1 className='display-5 fw-bold py-5 my-4'>{t("greeting")}</h1>
      <p className='fs-3'>{t("text")}</p>
  </div>

                                                      {/* conntact */}
  <div>
      <p className={`fs-3 fw-bold pb-5 ${language === 'en' ? '' : 'text-end'}`}>{t("contact")}</p>
      <div className={`row g-5 ${language === 'en' ? '' : 'flex-row-reverse'}`}>
        <div className="col-md-6">
          <div className='d-flex align-items-center flex-column fs-5'>
            <div className={`${style.infoSize}`}>
              <img className='w-100' src={infoLogo} alt="AlMekyal Logo" />
            </div>
            <h3 className={`pb-3 text-center ${language === 'en' ? 'fs-4' : ''}`}>{t("infoName")}</h3>
            <div className={`py-3 d-flex align-items-center ${language === 'en' ? '' : 'flex-row-reverse'}`}>
              <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("mail")}</p>
              <Link className='text-white text-decoration-none' to={'mailto:info@almekyal.com'}>info@almekyal.com</Link>
            </div>
            <div className={`py-3 d-flex align-items-center ${language === 'ar' ? 'flex-row-reverse' : ''}`}>
              <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("phone")}</p>
              <Link className='text-white text-decoration-none' to={'tel:+966555116840'}>+966555116840 </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className='d-flex align-items-center flex-column fs-5'>
            <div className={`${style.logoSize}`}>
              <img className='w-100' src={labLogo} alt="AlMekyal Logo" />
            </div>
            <h3 className={`pb-xl-4 pb-lg-2 pb-3 mb-xl-0 mb-lg-5 text-center ${language === 'en' ? 'fs-4' : ''}`}>{t("labName")}</h3>
            <div className={`py-3 d-flex align-items-center ${language === 'en' ? '' : 'flex-row-reverse'}`}>
              <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("mail")}</p>
              <Link className='text-white text-decoration-none' to={'mailto:lab@almekyal.com'}>lab@almekyal.com </Link>
            </div>
            <div className={`py-3 d-flex align-items-center ${language === 'ar' ? 'flex-row-reverse' : ''}`}>
              <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("phone")}</p>
              <Link className='text-white text-decoration-none' to={'tel:+9660501533348'}>+9660501533348 </Link>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
  </>
}