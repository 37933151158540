import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(languageDetector)
.use(initReactI18next)
.init({
    debug: true,
    lng:"ar",
    resources: {
        ar: {
            translation: {
                greeting:"أهلاً بكم في المكيال السعودي",
                text:".نسعد بزيارتكم ونفيدكم بأنه جاري العمل على تطوير الموقع",
                contact:":للتواصل",
                infoName:"شركة المكيال السعودي للاستشارات الهندسية",
                labName:"مختبرات المكيال السعودي الهندسية",
                mail:" :البريد الإلكتروني",
                phone:" :الجوال",
            },
        },
        en: {
            translation: {
                greeting:"Welcome at AlMekyal AlSaudi",
                text:"“We are happy to have you visit us and would like to inform you that we are currently working on the site’s development.”",
                contact:"For Contact:",
                infoName:"AlMekyal AlSaudi Engineering Consulting Company",
                labName:"AlMekyal AlSaudi Engineering Laboratories",
                mail:"Email: ",
                phone:"Phone: ",
            },
        },
    }
})