import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './../Navbar/Navbar';
import Footer from './../Footer/Footer';
export default function Layout() {

  return <>
  <div className='bg-main roboto-font'>
    <div className="container min-vh-100 h-100 d-flex flex-column justify-content-between">
      <Navbar/>
        <Outlet></Outlet>
      <Footer/>
    </div>
  </div>
  </>
}
