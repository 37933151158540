import React, { useContext } from 'react';
import style from './Navbar.module.css';
import { LangContext } from './../Context/LangContext';
import i18n from 'i18next';

export default function Navbar() {

  let {language, setLanguage}= useContext(LangContext);
  
  function toggleLanguage(){
      const newLanguage = language === 'en' ? 'ar' : 'en';
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    };

  return <>
      <nav className="navbar py-5">
      <span className={`nav-item w-100 d-flex align-items-center ${language === 'en' ? 'justify-content-start' : 'justify-content-end'}`}>
          <button
            className={`btn text-white border-0 fs-5 ${language === 'en' ? 'cairo-font fw-bold' : 'roboto-font fw-semibold'}`}
            onClick={toggleLanguage}
          >
            {language === 'en' ? 'العربية' : 'English'}
          </button>
        </span>
      </nav>
    </>
}
